<template>
  <scroll-container>
    <TermsAndConditions />
  </scroll-container>
</template>

<script>
import PageMixin from '../mixins/Page'
import TermsAndConditions from '../legal/TermsAndConditions.vue'

export default {
  mixins: [PageMixin],

  components: {
    TermsAndConditions
  }
}
</script>
